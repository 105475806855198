import { render, staticRenderFns } from "./snack-bar.vue?vue&type=template&id=bc8fa698&scoped=true&"
import script from "./snack-bar.vue?vue&type=script&lang=ts&"
export * from "./snack-bar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc8fa698",
  null
  
)

export default component.exports